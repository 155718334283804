import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react'
import { uuid } from 'uuidv4'
import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import slice from 'lodash/slice'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Tag from 'antd/lib/tag'
import Tabs from 'antd/lib/tabs'
import Spin from 'antd/lib/spin'
import Input from 'antd/lib/input'
import Table from 'antd/lib/table'
import Avatar from 'antd/lib/avatar'
import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import Select from 'antd/lib/select'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import Typography from 'antd/lib/typography'
import CopyOutlined from '@ant-design/icons/CopyOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import ReloadOutlined from '@ant-design/icons/ReloadOutlined'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import { MerchantSettings } from '@slerp/controls'
import { filterSelectOption } from '@slerp/antd-utils'
import { useSession } from '@slerp/accounts'
import {
  GET_MERCHANT_PRODUCTS,
  GET_MERCHANT_CATEGORIES,
  GET_MERCHANT_SETTINGS,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  DUPLICATE_PRODUCT,
  GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS,
  ARCHIVE_PRODUCT,
  UNARCHIVE_PRODUCT
} from './ProductQueries'
import VariantGroups from './VariantGroups'
import ModifierGroups from './ModifierGroups'
import ProductImages from './Images'
import Pricing from './Pricing'
import ArchivedProductVariantsList from './Variants/ArchivedProductVariantsList'
import Loading from '../../Utils/Loading'
import './Products.css'
import CategoryArrangement from './ProductArrangement/CategoryArrangement'
import { ReorderIcon } from '@slerp/assets'
import { openArchiveWarning } from './../../Utils/ArchiveWarning'
import { CategoryIdsParam } from 'components/Locations/Dashboard/types'
import { generateAssetURL } from 'packages/@slerp/helpers/asset-url'
import { QUERY_MERCHANT_IS_SEO_ENABLED } from 'components/Merchants/MerchantQueries'
import { SlerpDrawer } from 'components/Widgets/SlerpDrawer/SlerpDrawer'
import styled from '@emotion/styled'

const ProductForm = lazy(() => import('./Form'))

interface AdditionalImage {
  id: string
}

export interface CalorieData {
  id: string
  calories_per_serving: number
  serving_unit: string
  amount_per_serving: number
}

export interface ProductCategory {
  id: string
  product_id: string
  category_id: string
  category?: {
    name: string
  }
}

interface DefaultVariant {
  id: string
  sku: string
  price: number
  vat: number
  price_with_vat: number
  image: string
  alcoholic: boolean
  additional_images: AdditionalImage[]
  calorie_data: CalorieData
}

interface FulfillmentTypeAvailability {
  id: string
  product_id: null | string
  modifier_id: null | string
  fulfillment_type: 'delivery' | 'pickup' | 'order_at_table'
  is_available: boolean
  inserted_at: string
  updated_at: string
}

export interface Product {
  id: string
  sku: string
  source_id: string
  default_variant_id: string
  name: string
  description: string
  seo_description: string
  default_price: number
  default_vat: number
  price_with_vat: number
  default_limit: number
  alcoholic: boolean
  inserted_at: string
  archived_at: null | string
  publish_to_stores: boolean
  allergens: string[]
  availabilities: FulfillmentTypeAvailability[]
  default_variant: DefaultVariant[]
  categories?: ProductCategory[]
}

interface Category {
  id: string
  name: string
  slug: string
}

interface ProductsProps {
  mockCategoryIdsParam?: CategoryIdsParam[]
  skip?: boolean
}

const { TabPane } = Tabs
const { Option } = Select
const { Text } = Typography

const DATE_FORMAT = 'DD-MM-YYYY'
const TIME_FORMAT = 'HH:mm'

const Products = (props: ProductsProps) => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [products, setProducts] = useState<Array<Product>>([])
  const [categories, setCategories] = useState<Array<Category>>([])
  const [productStatusFilter, setProductStatusFilter] =
    useState<string>('active')
  const [categoryIdFilter, setCategoryIdFilter] = useState<string>('all')
  const [categoryIds, setCategoryIds] = useState<CategoryIdsParam[]>([])
  const [fuzzySearchKeyword, setFuzzySearchKeyword] = useState('')
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [activeProduct, setActiveProduct] = useState<Product>({
    id: '',
    sku: '',
    default_variant_id: '',
    name: '',
    description: '',
    default_price: 0,
    default_vat: 0,
    default_limit: 0,
    alcoholic: false,
    publish_to_stores: true,
    default_variant: [
      {
        id: '',
        sku: '',
        price: 0,
        alcoholic: false,
        additional_images: []
      }
    ],
    allergens: [],
    availabilities: [],
    inserted_at: '',
    archived_at: null
  })
  const [merchantSettings, setMerchantSettings] = useState<MerchantSettings>()
  const [isSeoEnabled, setIsSeoEnabled] = useState<boolean>(false)
  const [processingProductRow, setProcessingProductRow] = useState<string>('')
  const {
    data: merchantProductsData,
    refetch: merchantProductsRefetch,
    loading: merchantProductsLoading
  } = useQuery(GET_MERCHANT_PRODUCTS, {
    variables: {
      merchantSlug: merchant.slug,
      isActive: true
    },
    notifyOnNetworkStatusChange: true
  })
  const [reorderProductDrawerVisible, setReorderProductDrawerVisible] =
    useState<boolean>(false)
  const [productsForArrangement, setProductsForArrangement] = useState<
    Array<Product>
  >([])
  const [productsWithCategories, setProductsWithCategories] = useState<
    Product[]
  >([])

  const {
    data: merchantCategoriesData,
    refetch: refetchMerchantCategoriesData,
    loading: isLoadingMerchantCategoriesData
  } = useQuery(GET_MERCHANT_CATEGORIES, {
    variables: {
      merchantId: merchant.id
    },
    onCompleted: (data) => {
      if (!data) return
      const { categories } = data

      const ids = categories?.map((category: Category) => ({
        category_id: { _eq: category.id }
      }))

      setCategoryIds(ids)
    },
    notifyOnNetworkStatusChange: true
  })

  const { data: merchantSettingsData } = useQuery(GET_MERCHANT_SETTINGS, {
    variables: {
      merchantSlug: merchant.slug
    }
  })

  const { loading: isLoadingSeoEnabled } = useQuery(
    QUERY_MERCHANT_IS_SEO_ENABLED,
    {
      variables: {
        id: merchant.id
      },
      onCompleted: (data) => {
        const { is_seo_enabled } = data.merchant
        setIsSeoEnabled(is_seo_enabled)
      }
    }
  )

  const {
    data: productsCategoriesData,
    refetch: refetchProductsCategoriesData,
    loading: isLoadingProductsCategories
  } = useQuery<{ products_categories: ProductCategory[] }>(
    GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS,
    {
      variables: {
        categoryIds: props.mockCategoryIdsParam ?? categoryIds
      },
      skip:
        props.skip ??
        (isLoadingMerchantCategoriesData || !merchantCategoriesData),
      notifyOnNetworkStatusChange: true
    }
  )

  const [archiveProduct] = useMutation(ARCHIVE_PRODUCT)
  const [unarchiveProduct] = useMutation(UNARCHIVE_PRODUCT)

  const [updateMerchantArrangementSettings] = useMutation(
    UPDATE_MERCHANT_ARRANGEMENT_SETTINGS
  )

  const [duplicateProduct] = useMutation(DUPLICATE_PRODUCT)

  useEffect(() => {
    if (!merchantProductsData) return
    if (!productsCategoriesData) return

    const { products } = merchantProductsData
    const { products_categories } = productsCategoriesData

    const variantsWithCategories = products.map((product: Product) => {
      const involvedCategories = products_categories.filter(
        (pc: ProductCategory) => pc.product_id === product.id
      )

      return {
        ...product,
        categories: involvedCategories
      }
    })

    setProductsWithCategories(variantsWithCategories)
  }, [merchantProductsData, productsCategoriesData])

  useEffect(() => {
    if (merchantSettingsData) {
      const { setting } = merchantSettingsData?.merchants[0]
      setMerchantSettings(setting)
    }
  }, [merchantSettingsData])

  useEffect(() => {
    if (merchantCategoriesData) {
      const { categories } = merchantCategoriesData
      setCategories(categories)
    }
  }, [merchantCategoriesData])

  useEffect(() => {
    const showDrawer = activeProduct.id ? true : false
    setIsDrawerVisible(showDrawer)
  }, [activeProduct])

  const filterProducts = useCallback(
    (products: Product[]) => {
      let tempProducts = Object.assign([], products)
      if (categoryIdFilter !== 'all') {
        tempProducts = Object.assign(
          [],
          tempProducts.filter((product: Product) => {
            return product.categories?.some(
              (category) => category.category_id === categoryIdFilter
            )
          })
        )
      }
      if (productStatusFilter === 'active') {
        tempProducts = Object.assign(
          [],
          tempProducts.filter((product: Product) => {
            return isEmpty(product.archived_at)
          })
        )
      } else {
        tempProducts = Object.assign(
          [],
          tempProducts.filter((product: Product) => {
            return !isEmpty(product.archived_at)
          })
        )
      }
      if (fuzzySearchKeyword) {
        tempProducts = Object.assign(
          [],
          tempProducts.filter((product: Product) => {
            const { name } = product
            return name.toLowerCase().includes(fuzzySearchKeyword.toLowerCase())
          })
        )
      }

      return tempProducts
    },
    [categoryIdFilter, productStatusFilter, fuzzySearchKeyword]
  )

  useEffect(() => {
    if (productStatusFilter === 'active') {
      merchantProductsRefetch({
        isActive: true
      })
      refetchMerchantCategoriesData()
      refetchProductsCategoriesData()
    }
    if (productStatusFilter === 'archived') {
      merchantProductsRefetch({
        isActive: false
      })
      refetchMerchantCategoriesData()
      refetchProductsCategoriesData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStatusFilter])

  useEffect(() => {
    if (productsWithCategories) {
      const filteredProductsForArrangement = productsWithCategories.filter(
        (product: Product) => isEmpty(product.archived_at)
      )
      setProductsForArrangement(filteredProductsForArrangement)
      setProducts(filterProducts(productsWithCategories))
    }
  }, [productsWithCategories, filterProducts])

  const debouncedFuzzySearchKeyword = debounce(setFuzzySearchKeyword, 200)

  const handleToggleProductArchiveState = (
    productId: string,
    productName: string,
    setToActive: boolean,
    defaultVariantId: string
  ) => {
    setProcessingProductRow(productId)
    const toggleProductArchiveState = setToActive
      ? unarchiveProduct
      : archiveProduct

    toggleProductArchiveState({
      variables: { productId: productId }
    })
      .then((result) => {
        let updatedSettings = {}
        if (merchantSettings) {
          if (setToActive) {
            // restore to arrangement
            const key =
              parseInt(
                Object.keys(merchantSettings.product_arrangement).pop()!
              ) + 1
            updatedSettings = {
              ...merchantSettings,
              product_arrangement: {
                ...merchantSettings.product_arrangement,
                [key]: productId
              }
            }
          } else {
            // remove from arrangement
            const newArrangement = Object.values(
              merchantSettings.product_arrangement
            ).filter(
              (arrangementProductId: string) =>
                arrangementProductId !== productId
            )
            updatedSettings = {
              ...merchantSettings,
              product_arrangement: { ...newArrangement }
            }
          }

          openArchiveWarning({
            itemId: productId,
            itemName: productName,
            restore: !setToActive
              ? () =>
                  handleToggleProductArchiveState(
                    productId,
                    productName,
                    true,
                    defaultVariantId
                  )
              : null,
            description: `product has been ${
              setToActive ? 'restored' : 'archived'
            }`
          })

          updateMerchantArrangementSettings({
            variables: {
              merchant: merchant.slug,
              arrangement: updatedSettings
            }
          })
        }
        client.resetStore()
        setProcessingProductRow('')
      })
      .catch((error: Error) => {
        setProcessingProductRow('')
        message.destroy()
        openArchiveWarning({
          itemId: productId,
          itemName: productName,
          description: `failed to ${
            setToActive ? 'restore' : 'archive'
          } product due to ${error.message}`
        })
      })
  }

  const handleProductDuplication = (productId: string, productName: string) => {
    setProcessingProductRow(productId)
    duplicateProduct({
      variables: {
        productId: productId
      }
    })
      .then(() => {
        message.destroy()
        setProcessingProductRow('')
        message.success(`Successfully duplicated ${productName}`)
        client.resetStore()
      })
      .catch((error) => {
        setProcessingProductRow('')
        message.destroy()
        message.error(
          `Unable to duplicate ${productName} due to ${error.message}`,
          3
        )
      })
  }

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (productName: string, product: Product) => {
        // remove extra fields
        const { default_variant, publish_to_stores, default_variant_id } =
          product

        const formattedProduct = {
          ...product,
          default_variant,
          publish_to_stores: publish_to_stores ?? false,
          default_price: (default_variant[0] || {}).price,
          price_with_vat: (default_variant[0] || {}).price_with_vat,
          sku: (default_variant[0] || {}).sku || product.sku,
          alcoholic: (default_variant[0] || {}).alcoholic
        }

        // Each product should have a default variant, but we will be making this here a non-breaking error
        const { image: fileName } = default_variant[0] ?? ''

        const imageUrl = generateAssetURL({
          id: default_variant_id,
          type: 'variant',
          size: 'thumb',
          fileName: fileName,
          itemName: productName
        })

        return (
          <>
            <Row className='_mb-0' align='middle' gutter={[8, 8]}>
              <Col span={3}>
                <Avatar size={30} shape='square' src={imageUrl} />
              </Col>
              <Col span={21}>
                <span
                  onClick={() => setActiveProduct(formattedProduct)}
                  className='cursor-pointer'
                >
                  <Text
                    style={{ width: 220 }}
                    // ellipsis={{ tooltip: productName }}
                  >
                    {productName}
                  </Text>
                </span>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      render: (categories: Category[]) => {
        let aggregatedViewEnabled = false
        let visibleGroups = []
        let aggregatedCategoryGroups = []

        if ((categories || []).length === 3) {
          visibleGroups = [...categories]
        } else {
          aggregatedViewEnabled = (categories || []).length > 2
          visibleGroups = aggregatedViewEnabled
            ? slice(categories, 0, 2)
            : [...categories]
          aggregatedCategoryGroups = slice(categories || [], 2)
        }

        const tags = visibleGroups.map(({ category }: Category, key) => (
          <Tag key={key} className='-basic'>
            {category?.name}
          </Tag>
        ))

        if (aggregatedViewEnabled) {
          tags.push(
            <Tooltip
              title={
                <>
                  {aggregatedCategoryGroups.map(({ category }) => (
                    <>
                      <span>{category?.name}</span>
                      <br />
                    </>
                  ))}
                </>
              }
            >
              <Tag className='-basic -plain-text-format'>
                {`+ ${aggregatedCategoryGroups.length} more categories`}
              </Tag>
            </Tooltip>
          )
        }
        return <>{tags}</>
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (productName: string, product: Product) =>
        (product.default_variant[0]?.price || 0).toFixed(2)
    },
    {
      title: 'SKU',
      key: 'sku',
      render: (record: Product) => record.sku
    },
    {
      title: 'Created At',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      width: 120,
      align: 'right',
      render: (insertedAt: string) => {
        return (
          <>
            <Row justify='end' className='_mb-0'>
              <Col>{moment(insertedAt).format(DATE_FORMAT)}</Col>
            </Row>
            <Row justify='end' className='_mb-0'>
              <Col>
                <span>{moment(insertedAt).format(TIME_FORMAT)}</span>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 180,
      align: 'center',
      render: (_value: any, product: Product) => {
        const isArchived = !isEmpty(product.archived_at)
        const icon = isArchived ? <ReloadOutlined /> : <DeleteOutlined />

        const isOperationInProgress = !isEmpty(processingProductRow)
        const isCurrentRowProcessing = processingProductRow === product.id

        const { default_variant, publish_to_stores } = product

        const formattedProduct = {
          ...product,
          default_variant: default_variant,
          publish_to_stores: publish_to_stores ?? false,
          default_price: (default_variant[0] || {}).price,
          price_with_vat: (default_variant[0] || {}).price_with_vat,
          alcoholic: (default_variant[0] || {}).alcoholic
        }

        return (
          <>
            <Row
              gutter={[8, 8]}
              align='middle'
              justify='center'
              className='_mb-0'
            >
              <Col>
                <Tooltip title='Edit' color='#d092dd'>
                  <Button
                    size='small'
                    icon={<EditOutlined />}
                    disabled={isOperationInProgress}
                    loading={isCurrentRowProcessing}
                    onClick={() => setActiveProduct(formattedProduct)}
                    data-testid='edit-product-btn'
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title='Duplicate' color='#d092dd'>
                  <Button
                    size='small'
                    icon={<CopyOutlined />}
                    disabled={isOperationInProgress}
                    loading={isCurrentRowProcessing}
                    onClick={() =>
                      handleProductDuplication(product.id, product.name)
                    }
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  title={isArchived ? 'Restore' : 'Archive'}
                  color='#d092dd'
                >
                  <Button
                    size='small'
                    icon={icon}
                    disabled={isOperationInProgress}
                    loading={isCurrentRowProcessing}
                    onClick={() =>
                      handleToggleProductArchiveState(
                        product.id,
                        product.name,
                        isArchived,
                        product.default_variant_id
                      )
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        )
      }
    }
  ]

  const closeProductDrawer = () => {
    setIsDrawerVisible(false)
  }

  return (
    <>
      <SlerpDrawer
        width={840}
        onClose={() => closeProductDrawer()}
        visible={isDrawerVisible}
        title='Edit product'
        data-testid='edit-product-drawer'
      >
        {({ productTabHandler, formRef, pricingFormRef }) => (
          <Tabs onChange={(key) => productTabHandler(key)}>
            <TabPane tab='General' key='general'>
              <Suspense fallback={<Spin />}>
                <ProductForm
                  key={uuid()}
                  isSeoEnabled={isSeoEnabled}
                  productInfo={activeProduct}
                  successCallback={() => closeProductDrawer()}
                  discardCallback={() => closeProductDrawer()}
                  formRef={formRef}
                />
              </Suspense>
            </TabPane>
            <TabPane
              tab={
                <>
                  Images
                  <Tooltip
                    title={
                      <a
                        href='https://support.slerp.com/knowledge/uploading-images'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Uploading Images
                      </a>
                    }
                    placement='bottom'
                  >
                    <QuestionCircleOutlined
                      className='_mr-0 _ml-8'
                      style={{ marginRight: '-8px' }}
                    />
                  </Tooltip>
                </>
              }
              key='images'
            >
              <ProductImages
                key={uuid()}
                defaultVariantId={activeProduct.default_variant[0].id}
                additionalImages={
                  activeProduct.default_variant &&
                  activeProduct.default_variant[0] &&
                  activeProduct.default_variant[0].additional_images
                }
                productName={activeProduct.name}
                image={activeProduct.default_variant[0].image}
              />
            </TabPane>
            <TabPane
              tab={
                <>
                  Variants
                  <Tooltip
                    title={
                      <span>
                        This lets you create alternate versions of the same
                        product such as flavour or size.
                      </span>
                    }
                    placement='bottom'
                  >
                    <QuestionCircleOutlined
                      className='_mr-0 _ml-8'
                      style={{ marginRight: '-8px' }}
                    />
                  </Tooltip>
                </>
              }
              key='variants'
            >
              <Tabs>
                <TabPane tab='Active' key='1'>
                  <VariantGroups key={uuid()} productId={activeProduct.id} />
                </TabPane>
                <TabPane tab='Archived' key='2'>
                  <ArchivedProductVariantsList
                    key={uuid()}
                    productId={activeProduct.id}
                  />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab='Modifiers' key='modifiers'>
              <ModifierGroups key={uuid()} productId={activeProduct.id} />
            </TabPane>
            <TabPane
              tab={
                <>
                  Pricing
                  <Tooltip
                    title={
                      <span>Configure pricing based on fulfilment type</span>
                    }
                    placement='bottom'
                  >
                    <QuestionCircleOutlined
                      className='_mr-0 _ml-8'
                      style={{ marginRight: '-8px' }}
                    />
                  </Tooltip>
                </>
              }
              key='pricing'
            >
              <Pricing
                productId={activeProduct.id}
                baseName={activeProduct.name}
                discardCallback={() => closeProductDrawer()}
                pricingFormRef={pricingFormRef}
              />
            </TabPane>
          </Tabs>
        )}
      </SlerpDrawer>

      <Drawer
        width={720}
        onClose={() => setReorderProductDrawerVisible(false)}
        visible={reorderProductDrawerVisible}
        data-testid='reorder-products-drawer'
        title='Reorder Categories and Products'
        className='reorder-drawer'
      >
        <CategoryArrangement
          products={productsForArrangement}
          setReorderProductDrawerVisible={setReorderProductDrawerVisible}
          reorderProductDrawerVisible={reorderProductDrawerVisible}
        />
      </Drawer>
      <Row className='_mt-16'>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Row gutter={[8, 8]}>
                <Col span={9}>
                  {categories && (
                    <Select
                      data-testid='products-category-filter'
                      showSearch
                      className='width-100'
                      defaultValue='all'
                      optionFilterProp='name'
                      filterOption={filterSelectOption}
                      onChange={setCategoryIdFilter}
                    >
                      <Option key='all' value='all'>
                        All Categories
                      </Option>
                      {categories.map((category) => {
                        return (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Col>
                <Col span={5}>
                  <Select
                    data-testid='products-archive-state-filter'
                    className='width-100'
                    defaultValue={productStatusFilter}
                    onChange={setProductStatusFilter}
                  >
                    <Option value='active'>Active</Option>
                    <Option value='archived'>Archived</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify='end' gutter={[8, 8]} align='middle'>
                <Col>
                  <StyledInput
                    data-testid='products-search-filter'
                    prefix={<SearchOutlined />}
                    placeholder='Search'
                    onChange={(e) =>
                      debouncedFuzzySearchKeyword(e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Tooltip
                    title={
                      <span>
                        <a
                          href='https://support.slerp.com/knowledge/re-ordering-categories'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Reorder
                        </a>{' '}
                        the way your categories are displayed on your store by
                        dragging and dropping. Don't forget to hit save.
                      </span>
                    }
                  >
                    <Button
                      icon={<ReorderIcon />}
                      onClick={() => setReorderProductDrawerVisible(true)}
                      data-testid='reorder-products-drawer-btn'
                    >
                      <span className='pl-half-rem'>REORDER</span>
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {(merchantProductsLoading || isLoadingProductsCategories) && (
                <Loading />
              )}
              {!merchantProductsLoading &&
                !isLoadingProductsCategories &&
                products && (
                  <Table
                    dataSource={products}
                    columns={columns}
                    data-testid='products-table'
                    pagination={{
                      defaultCurrent: page,
                      onChange: (page, pageSize) => {
                        setPage(page)
                      }
                    }}
                  />
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

const StyledInput = styled(Input)`
  width: 280px;
`

export default Products
